<template>
  <div class="pay-resoult-page">
    <div>
      <div class="resoult">
        <img :src="require(`@/assets/${payState.iconUrl}`)" alt="" />
      </div>
      <div class="desc">{{ payState.resoult }}</div>
      <div class="money" v-if="success">
        支付金额：{{ (orderAmount / 100).toFixed(2) }}
      </div>
      <div
        v-if="this.productInfo.productCode == 'H013' || this.productInfo.productCode == 'H016'"
        style="margin-top: 20px; text-align: center"
      >
        <h4>权益兑换码如下：</h4>
        <p style="font-weight: bold; color: red; font-size: 0.4rem">
          {{ code }}
        </p>
        <p>请截图保存权益兑换码</p>
      </div>
      <van-button @click="goMain" type="primary">返回首页</van-button>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { components } from "./options";
import { sendOrder, payTelNumber } from "@/api";
import { create } from "domain";
import { log } from "util";

const ProductModule = namespace("product");
const orderModule = namespace("order");
const ProductState = ProductModule.State;
const orderGetter = orderModule.Getter;
const orderState = orderModule.State;
@Component({
  components,
})
class PayResult extends Vue {
  @orderState("orderInfo") orderInfo;
  @orderGetter("orderAmount") orderAmount;
  @ProductState("productInfo") productInfo;
  success = true;
  payState = {
    resoult: "支付成功",
    iconUrl: "success.png",
  };
  code = "";
  phone = "";
  async mounted() {
    const { success } = this.$route.query;
    if (!success || success == "false") {
      this.success = false;
      this.payState = {
        resoult: "支付失败",
        iconUrl: "fail.png",
      };
    }
    this.phone = window.location.search.split("phone=")[1].split("=")[0];
    payTelNumber({
      phoneNumber: this.phone,
    }).then((res) => {
      this.code = res.giftCardNumber;
    });
    // else {
    //   await sendOrder(this.orderInfo);
    // }
  }
  goMain() {
    // this.$router.go(-3); //返回上一层
    // console.log()
    window.location.href = `${location.origin}/?productCode=${this.productInfo.productCode}&insuranceCompanyCode=${this.productInfo.insuranceCompanyCode}`;
    this.$Glutton.stopRecording();
  }
  unmounted() {
    this.$Glutton.stopRecording();
  }
}
export default PayResult;
</script>

<style lang="scss" src="./index.scss" scoped></style>
